<template>
    <div class="list-forum-threads">
        <div class="list-forum-threads-table">
            <div class="list-forum-threads-table__heading">
                <div class="spacing-author">{{ $lang.components.listThreads.author }}</div>
                <div class="spacing-title">{{ $lang.components.listThreads.title }}</div>
                <div class="spacing-category">{{ $lang.components.listThreads.thread }}</div>
                <div class="spacing-time">{{ $lang.components.listThreads.date }}</div>
                <div class="spacing-answers flex h-4 items-center justify-center">
                    <img :src="$assets.gray.replies" alt="cantidad de respuestas" />
                </div>
            </div>
            <div class="list-forum-threads-table__body">
                <ul v-if="threadList.length">
                    <li v-for="(thread, key) in threadList" :key="key">
                        <div class="spacing-author author">
                            <NuxtLink
                                no-prefetch
                                :to="'/dashboard/' + thread.user.id"
                                rel="follow"
                                class="image"
                            >
                                <MiscUserPic :badge="true" :user="thread.user"></MiscUserPic>
                            </NuxtLink>
                            <div class="info">
                                <NuxtLink
                                    no-prefetch
                                    :to="'/dashboard/' + thread.user.id"
                                    rel="follow"
                                    class="info__user"
                                >
                                    {{ thread.user.username }}
                                </NuxtLink>
                                <span
                                    v-if="thread.user.role && thread.user.role.name !== 'Usuario'"
                                    class="info__rank"
                                >
                                    <img :src="$assets.primary.verified" alt="icono verificado" />
                                    {{ thread.user.role.name }}
                                </span>
                            </div>
                        </div>
                        <div class="spacing-title title">
                            <NuxtLink
                                no-prefetch
                                :to="`/${$lang.routes.forum}/${thread.slug}`"
                                class="title-description"
                                rel="follow"
                                >{{ thread.title }}</NuxtLink
                            >
                            <div class="title-footer">
                                &nbsp;·&nbsp;
                                <span>
                                    {{ formatDatAndMonth(thread.created_at) }}
                                </span>
                                &nbsp;·&nbsp;
                                <span
                                    @click="
                                        changeCategory(
                                            thread.category
                                                ? thread.category.name
                                                : $lang.components.listThreads.all,
                                        )
                                    "
                                    >{{
                                        thread.category
                                            ? thread.category.name
                                            : $lang.components.listThreads.all
                                    }}</span
                                >
                                &nbsp;·&nbsp;
                                <NuxtLink
                                    no-prefetch
                                    :to="`/${$lang.routes.forum}/${thread.slug}`"
                                    class="flex"
                                    rel="follow"
                                >
                                    <img :src="$assets.gray.replies" alt="cantidad de respuestas" />&nbsp;
                                    {{ thread.comments_count || 0 }}
                                </NuxtLink>
                            </div>
                        </div>
                        <button
                            class="spacing-category category hover:underline"
                            @click="
                                changeCategory(
                                    thread.category ? thread.category.name : $lang.components.listThreads.all,
                                )
                            "
                        >
                            {{ thread.category ? thread.category.name : $lang.components.listThreads.all }}
                        </button>
                        <p class="spacing-time time">
                            {{ formatDatAndMonth(thread.created_at) }}
                        </p>
                        <p class="spacing-answers answers">
                            <img :src="$assets.gray.replies" alt="cantidad de respuestas" />
                            {{ thread.comments_count || 0 }}
                        </p>
                    </li>
                </ul>
                <p v-else class="list-forum-threads-table__message">
                    {{ $lang.components.listThreads.results_not_found }}
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { useDateFunctions } from '~/composables/DateFunctions'
import type { Models } from '~/types/models'

export default defineComponent({
    name: 'ListThreads',
    props: {
        threadList: {
            type: Array as PropType<Models.Forum[]>,
            required: true,
        },
    },
    data() {
        const { formatDatAndMonth } = useDateFunctions()
        return {
            formatDatAndMonth,
        }
    },
    methods: {
        changeCategory(name: string) {
            this.$emit('changeCategory', name)
        },
    },
})
</script>

<style lang="postcss" scoped>
.list-forum-threads {
    @apply pb-3;
    &-table {
        @apply min-w-full text-gray-800;
        &__heading {
            @apply flex w-full rounded-t-xl bg-[#E3DEF2] p-1.5;
            div {
                @apply gap-1 font-semibold;
            }
        }
        &__message {
            @apply w-full bg-white py-6 text-center text-site-primary;
        }
        li {
            @apply flex w-full gap-0.5 bg-white p-2 text-sm text-gray-800;
            &:not(:last-of-type) {
                @apply border-b;
            }
            .author {
                @apply flex flex-col flex-wrap items-start gap-x-1 sm:flex-row sm:items-center;
                .image {
                    @apply h-9 w-9 flex-none rounded-full;
                    img {
                        @apply h-full w-full rounded-full object-cover;
                    }
                }
                .info {
                    &__user {
                        @apply overflow-hidden break-all text-xs hover:underline;
                    }
                    &__rank {
                        @apply flex w-fit gap-1 self-center rounded-xl border bg-[#EFEEF2] p-1 text-xs text-[#3D3D3D];
                    }
                }
            }
            .title {
                @apply flex flex-col justify-between;
                .title-description {
                    @apply line-clamp-2 text-sm text-site-primary hover:underline md:text-base;
                }
                .title-footer {
                    @apply flex items-center justify-end font-sans text-xs text-gray-800 md:hidden;
                    img {
                        @apply w-3;
                    }
                }
            }
            .answers {
                @apply flex-wrap justify-center gap-1.5 font-medium;
                img {
                    @apply h-4;
                }
            }
        }
    }
    .spacing-author {
        @apply w-4/12 md:w-2/12;
    }
    .spacing-title {
        @apply w-8/12 md:w-6/12;
    }
    .spacing-category {
        @apply hidden w-1/4 items-center px-2 md:flex md:w-2/12;
    }
    .spacing-time {
        @apply hidden w-1/4 items-center md:flex md:w-1/12;
    }
    .spacing-answers {
        @apply hidden w-1/4 items-center md:flex md:w-1/12;
    }
}
</style>
