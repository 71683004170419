<template>
    <div class="chat-index">
        <LayoutBreadcrumbs :layers="breadcrumbs" text-color="text-gray-900" />
        <div class="chat-index__content">
            <main class="main">
                <h1 class="main__title">{{ $lang.pages.foros.forum }}</h1>

                <div class="mb-2 flex w-full justify-between gap-2 lg:hidden">
                    <NuxtLink
                        no-prefetch
                        :to="`/${$lang.routes.share}/${$lang.routes.chat}`"
                        class="create-new-thread flex w-1/2 md:w-1/4"
                    >
                        <img :src="$assets.white.createThread" :alt="$lang.pages.foros.create_new_forum" />
                        <span class="overflow-hidden text-ellipsis whitespace-nowrap">{{
                            $lang.pages.foros.create_new_forum
                        }}</span>
                    </NuxtLink>
                    <div
                        class="mt-5; relative flex w-1/2 items-center justify-center rounded-lg border border-opacity-5 bg-white p-1.5 text-sm md:w-1/4"
                    >
                        <!-- START DROPDOWN CATEGORY -->
                        <button class="w-full" type="button" @click="dropdown.show = !dropdown.show">
                            <h3 class="flex items-center justify-between gap-1 p-1 text-sm">
                                {{ dropdown.selected.name }}
                                <img
                                    class="h-1.5"
                                    :class="dropdown.show ? 'rotate-180 transition-all' : 'transition-all'"
                                    :src="$assets.black.dropdown"
                                    alt="dropdown"
                                />
                            </h3>
                        </button>
                        <DropdownSelect
                            v-if="dropdown.show"
                            class="absolute left-0 top-full w-full rounded-xl"
                            :options="dropdown.data"
                            :selected="dropdown.selected"
                            @newSelected="onChangeCategory"
                        />
                    </div>
                </div>

                <LayoutThreads :thread-list="filteredThreads" @changeCategory="onChangeCategoryByName" />

                <ButtonLoadMore
                    v-if="pagination.show"
                    :handler="loadMoreForos"
                    :loading="pagination.loading"
                />
            </main>

            <aside class="sidebar">
                <NuxtLink
                    no-prefetch
                    :to="`/${$lang.routes.share}/${$lang.routes.chat}`"
                    class="create-new-thread hidden lg:flex"
                >
                    <img :src="$assets.white.createThread" :alt="$lang.pages.foros.create_new_forum" />
                    {{ $lang.pages.foros.create_new_forum }}
                </NuxtLink>

                <section class="categories-dropdown">
                    <div class="content_head">
                        <h3 class="content_head__heading">{{ $lang.pages.foros.categories }}</h3>
                    </div>

                    <!-- START DROPDOWN CATEGORY -->
                    <div class="dropdown">
                        <button
                            type="button"
                            @click="dropdown.show = !dropdown.show"
                            class="dropdown-title"
                            :class="dropdown.show ? 'border-b-transparent' : ''"
                        >
                            <h3>{{ dropdown.selected.name }}</h3>
                            <img
                                class="dropdown-title__icon-drop"
                                :class="dropdown.show ? 'rotate-180 transition-all' : 'transition-all'"
                                :src="$assets.black.dropdown"
                                alt="dropdown"
                            />
                        </button>
                        <DropdownSelect
                            v-if="dropdown.show"
                            class="absolute left-0 top-full w-full rounded-xl"
                            :options="dropdown.data"
                            :selected="dropdown.selected"
                            @newSelected="onChangeCategory"
                        />
                    </div>
                    <!-- END DROPDOWN CATEGORY -->
                </section>
                <LayoutGallery
                    v-if="stores"
                    :path="`/${$lang.routes.brands}`"
                    :title="$lang.pages.foros.popular_shops"
                    :info="stores"
                    :max-items="15"
                />
            </aside>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useRootStore } from '~/store/root'
import type { Models } from '~/types/models'

import { replacePlaceholders } from '~/util/textFunction'
import type { Api } from '~~/global'

import { useDateFunctions } from '~/composables/DateFunctions'

const { currentDate } = useDateFunctions()

const { $lang } = useNuxtApp()
const { buildHeaders, baseURL, endpoints } = useApiConfig()

const { data: responseData, error } = await useAsyncData('forum-response-data', () =>
    $fetch<Api.Responses.Pages.Forum>(endpoints.pages.forum.path, {
        headers: buildHeaders(),
        baseURL,
        method: 'GET',
    }).catch((e) => e.data),
)

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

let forum = responseData.value

const currentDates = currentDate()

const {
    public: { origin, siteName },
} = useRuntimeConfig()

const [title, description] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':day', String(currentDates.day) || ''],
        [':year', String(currentDates.year) || ''],
        [':site', siteName || ''],
    ],
    [forum.page.title || '', forum.page.description || ''],
)

const Route = useRoute()

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
const pagination = reactive({
    loading: false,
    page: 1,
    show:
        forum.page.data.forums.meta.total > 0 &&
        forum.page.data.forums.meta.current_page < forum.page.data.forums.meta.last_page,
})

const filterBy = ref('Todo')

const dropdown = reactive({
    show: false,
    data: [
        { name: 'Todo' },
        ...forum.page.data.categories.map((category: any) => ({
            name: category.name,
        })),
    ],
    selected: { name: 'Todo' },
})

const filteredThreads = computed(() => {
    const chats = forum.page.data.forums.data

    if (filterBy.value !== 'Todo') {
        return chats.filter((item: Models.Forum) => item.category && item.category.name === filterBy.value)
    }
    return chats
})

const RootStore = useRootStore()
const stores = computed(() => {
    return RootStore.layoutData?.data.sidebar?.stores || null
})
const breadcrumbs = computed(() => {
    return [
        {
            path: '/',
            title: $lang.pages.foros.home,
        },
        {
            path: '',
            title: $lang.pages.foros.forum,
        },
    ]
})

const onChangeCategory = (selected: { name: string }) => {
    console.log(dropdown)

    dropdown.selected = selected
    dropdown.show = false
    filterBy.value = selected.name
}
const onChangeCategoryByName = (name: string) => {
    filterBy.value = name
    dropdown.selected.name = name
}
const loadMoreForos = async () => {
    try {
        pagination.loading = true
        pagination.page = pagination.page + 1

        const newForumsResponse = await $fetch<Api.Responses.Pages.Forum>(endpoints.pages.forum.path, {
            headers: buildHeaders(),
            baseURL,
            method: 'GET',
            params: {
                page: pagination.page,
            },
        })

        forum.page.data.forums.data = [
            ...forum.page.data.forums.data,
            ...newForumsResponse.page.data.forums.data,
        ]

        pagination.loading = false
        pagination.show =
            newForumsResponse.page.data.forums.meta.total > 0 &&
            newForumsResponse.page.data.forums.meta.current_page <
                newForumsResponse.page.data.forums.meta.last_page
    } catch (e) {
        console.log('Error on loading forums: ', { e })
    }
}
</script>

<style lang="postcss" scoped>
.chat-index {
    @apply container;
    &__content {
        @apply container mb-5 pt-4 xl:flex;
    }

    .main {
        @apply gap-y-6 xl:order-2 xl:flex-grow;
        &__title {
            @apply text-2xl font-semibold text-gray-800;
        }
    }

    .sidebar {
        @apply mt-4 space-y-5 lg:order-1 xl:mr-5 xl:mt-0 xl:w-80 xl:flex-none;
    }
    .create-new-thread {
        @apply items-center justify-center gap-1 rounded-xl bg-site-primary p-2.5 text-white;
    }

    .categories-dropdown {
        @apply relative mt-5 hidden rounded-2xl border border-opacity-5 bg-white p-3 lg:block;
        .content_head {
            @apply mb-2 flex justify-between;
            &__heading {
                @apply text-lg font-semibold;
            }
            a {
                @apply self-center text-sm font-medium text-site-primary hover:underline;
            }
        }

        .dropdown {
            @apply relative w-full;
            &-title {
                @apply relative flex w-full items-center justify-between rounded-xl border bg-[#F8F7FC] p-2 font-sans;
                &__icon {
                    @apply h-4;
                }
                &__icon-drop {
                    @apply ml-5 h-1.5;
                }
            }
        }
    }
}
</style>
